import React from 'react'

const Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 394.9 500" height="1em">
      <path fill="currentColor" d="M78 496.25l-25-8.75c-10-3.75-12.5-6.25-17.5-12.5-10-12.5-15-21.25-16.25-48.75-1.25-12.5-2.5-17.5-7.5-23.75C8 396.25 8 393.75 8 386.25l2.5-17.5 2.5-20c0-10-1.25-12.5-7.5-31.25-6.25-13.75-6.25-32.5-3.75-50 3.75-17.5 5-22.5 10-28.75 5-3.75 7.5-2.5 6.25-8.75A327.5 327.5 0 0183 78.75c10-12.5 28.75-30 41.25-40 13.75-10 42.5-25 58.75-30C218-2.5 251.75-2.5 284.25 7.5s67.5 33.75 87.5 60c7.5 10 17.5 30 20 41.25 3.75 12.5 3.75 42.5 1.25 58.75-2.5 20-7.5 33.75-21.25 60l-11.25 26.25c0 2.5 0 5 5 5 12.5 0 12.5 10 3.75 40-8.75 26.25-15 37.5-28.75 51.25L308 376.25c-8.75 6.25-15 13.75-22.5 27.5l-7.5 17.5v-12.5c0-12.5-2.5-26.25-7.5-35-8.75-15-8.75-21.25-7.5-40 2.5-21.25 1.25-21.25 8.75-27.5 12.5-7.5 13.75-17.5 7.5-21.25-25-8.75-42.5 16.25-55 38.75L201.75 305l-51.25-46.25-12.5-6.25-26.25-16.25c-17.5-11.25-26.25-13.75-37.5-15-12.5 0-17.5 1.25-17.5 10 0 10 10 20 47.5 50l37.5 30L95.5 277.5c-35-26.25-55-38.75-65-40-5-1.25-8.75-1.25-12.5 3.75-8.75 7.5-7.5 17.5 5 30 5 6.25 33.75 31.25 46.25 41.25 18.75 15 41.25 33.75 47.5 41.25L93 337.5a550 550 0 00-72.5-43.75c-7.5 0-11.25 11.25-5 23.75 5 8.75 22.5 26.25 48.75 45C85.5 380 88 380 91.75 390c-5 0-5-1.25-12.5-6.25l-15-11.25C50.5 362.5 33 350 28 348.75c-7.5-1.25-11.25 1.25-11.25 8.75 0 12.5 6.25 23.75 35 51.25 18.75 17.5 18.75 16.25 22.5 30C78 450 85.5 465 100.5 486.25L108 500c-6.25 0-17.5 0-30-3.75z"/>
    </svg>
  )
}

export default React.memo(Icon)
